<script setup lang="ts">
import type {
  NovaHeaderNavigationEmits,
  NovaHeaderNavigationProps,
} from './NovaHeaderNavigationProps.types'

const emit = defineEmits<NovaHeaderNavigationEmits>()
const props = defineProps<NovaHeaderNavigationProps>()

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const searchBarExpand = ref(false)
const searchBarRef = ref()
const openSearchStore = useOpenSearchStore()
// const layoutStore = useLayoutStore()

watch(
  () => props.showSearchBar,
  (cur) => {
    const input = (searchBarRef.value?.$el as HTMLDivElement)?.querySelector(
      'input'
    ) as HTMLInputElement
    if (cur && input && !openSearchStore.keyword) {
      input.focus()
    }
  }
)

const handleOnSearchBarExpand = (isExpand: boolean) => {
  searchBarExpand.value = isExpand
  if (isExpand) emit('onFocus')
}

const handleOnHideSearchBar = () => {
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: '모바일 일때 > 헤더 > 검색 숨기기 버튼',
    eventLabel: t('cancel'),
    eventSlot: '헤더',
    eventI18nAddr: useKoreanTranslation('cancel'),
    eventComponent: 'Button',
  })
  emit('hideSearchBar')
}
const focusAutoCompleteList = (direction: string) => {
  emit('focusAutoCompleteList', direction)
}

const selectAutoCompleteItem = () => {
  emit('selectAutoCompleteItem')
}
</script>

<template>
  <div class="navigation">
    <div
      :class="[
        'search-bar',
        { expand: searchBarExpand },
        { on: showSearchBar },
      ]"
    >
      <NovaHeaderSearchBar
        ref="searchBarRef"
        @on-search="() => emit('hideSearchBar')"
        @on-focus="() => handleOnSearchBarExpand(true)"
        @on-blur="() => handleOnSearchBarExpand(false)"
        @focus-auto-complete-list="focusAutoCompleteList"
        @select-auto-complete-item="selectAutoCompleteItem"
      />
      <NovaButtonText
        :label="$t('cancel')"
        :theme="'transparent'"
        class="btn-cancel"
        @click="handleOnHideSearchBar"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: 100%;
  z-index: 2;

  @include mobile {
    gap: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    .navigations {
      width: 100%;
      height: 50px;
      padding: 0 10px;
      pointer-events: auto;
    }

    .search-bar {
      height: 50px;
      padding: 0 10px;
      pointer-events: none;
    }
  }

  .search-bar {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 360px;
    @include transition(max-width 0.3s);

    @include mobile {
      max-width: 100%;
      padding: 0 25px;
      background-color: $color-bg-3;
      opacity: 0;
      pointer-events: none;
      @include transition(opacity 0.2s);

      &.on {
        opacity: 1;
        pointer-events: auto;
      }
    }

    @include mobile {
      .search-button {
        display: none;
      }
    }

    &.expand {
      max-width: 100%;
    }

    .btn-cancel {
      display: none;
      padding: 0;
      flex-shrink: 0;

      @include mobile {
        display: inline-flex;
      }
    }
  }
}
</style>
