<script setup lang="ts">
import { ROUTES, routeSplitPrefix } from '@configs'
import { ChatButtonMode } from '@components/NovaChatButton/NovaChatButton.types'
import { LayerType } from '@store'

const { gtEvent } = useGoogleTag()
const layoutStore = useLayoutStore()
const { layers } = storeToRefs(layoutStore)
const openSearchStore = useOpenSearchStore()
const searchStore = useSearchStore()
const { autoComplete, keyword, selectedPanelKeyword } =
  storeToRefs(openSearchStore)
const isOpen = computed(() => layers.value.includes(LayerType.SEARCH_PANEL))
const headerRef = ref<HTMLElement | null>(null)
const showSearchBar = ref(false)
const router = useRouter()
const showChatButton = computed(() => {
  const name = useGetRouteName()
  return !name.includes(ROUTES.CREATOR.name)
})

// 검색 결과 페이지를 제외하고 최초 랜딩시 서치바 오픈 결정
const handleOnLadingShowSearchBar = (to: any) => {
  const routeName = String(to.name).split(routeSplitPrefix)[0]
  showSearchBar.value = routeName.includes(ROUTES.SEARCH.name)
}
const handleOnShowSearchBar = () => {
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: '헤더 > 검색 input 노출 버튼',
    eventLabel: '',
    eventSlot: '모바일',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  showSearchBar.value = true
}
const handleOnHideSearchBar = () => {
  showSearchBar.value = false
  layoutStore['layer:close'](LayerType.SEARCH_PANEL)
}
const updateHeaderBarRect = () => {
  const headerRect = headerRef.value?.getBoundingClientRect() || null
  const headerInnerRect =
    headerRef.value?.querySelector('.inner')?.getBoundingClientRect() || null
  layoutStore.updateHeaderRect(headerRect)
  layoutStore.updateHeaderInnerRect(headerInnerRect)
}

const handleOnSearchFocus = useDebounce(() => {
  layoutStore['layer:open'](LayerType.SEARCH_PANEL)
}, 100)

onMounted(() => {
  updateHeaderBarRect()
  window.addEventListener('resize', updateHeaderBarRect)
  if (!useCheckMobile()) {
    window.addEventListener('resize', handleOnHideSearchBar)
  }
  router.afterEach((to) => {
    handleOnLadingShowSearchBar(to)
  })
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', updateHeaderBarRect)
  if (!useCheckMobile()) {
    window.removeEventListener('resize', handleOnHideSearchBar)
  }
})

// 자동완성 패널의 값이 변화하면 자동 완성 창에서 선택된 값을 초기화
watch(
  () => autoComplete.value,
  () => {
    activeIndex.value = -1
  }
)

const keyType = ref(null)
const activeIndex = ref(-1)

const handleFocusAutoCompleteList = (direction: string) => {
  const totalItems = autoComplete.value.length
  if (direction === 'down') {
    if (activeIndex.value < totalItems - 1) {
      activeIndex.value++
    } else {
      activeIndex.value = 0
    }
  } else if (direction === 'up') {
    if (activeIndex.value > 0) {
      activeIndex.value--
    } else {
      activeIndex.value = totalItems - 1
    }
  }
  selectedPanelKeyword.value = autoComplete.value[activeIndex.value]
}

const selectAutoCompleteItem = async () => {
  if (activeIndex.value > 0) {
    await openSearchStore.fetchAutoComplete({
      keyword: selectedPanelKeyword.value,
    })
  }
  const selectedItem = autoComplete.value[activeIndex.value]
  if (selectedItem) {
    const trim = selectedItem.trim()
    searchStore.updateKeyword(trim)
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '헤더 > 검색',
      eventLabel: 'search',
      eventSlot: '헤더',
      eventI18nAddr: 'search',
      eventComponent: 'Input',
      searchContents: trim,
    })
    keyword.value = trim
    await layoutStore['layer:close'](LayerType.SEARCH_PANEL)
    await openSearchStore.fetchAutoComplete({
      keyword: trim,
    })
    await useNavigations({ url: ROUTES.SEARCH.path, query: { keyword: trim } })
  } else {
    keyword.value = selectedPanelKeyword.value
  }
}
</script>

<template>
  <header
    ref="headerRef"
    :class="['header', { 'mobile-gnb-hide': layoutStore.mobileGnbHide }]"
  >
    <div class="upper">
      <div class="inner">
        <div class="inner-item left">
          <div class="bi-group">
            <NovaLogo :is-go-home="true" :responsive="true" />
          </div>
        </div>

        <div :class="['inner-item center', { 'search-panel-opened': isOpen }]">
          <NovaHeaderNavigation
            :show-search-bar="showSearchBar"
            class="desktop-search-bar"
            @hide-search-bar="handleOnHideSearchBar"
            @on-focus="handleOnSearchFocus()"
            @focus-auto-complete-list="handleFocusAutoCompleteList"
            @select-auto-complete-item="selectAutoCompleteItem"
          />
        </div>

        <div class="inner-item right">
          <NovaHeaderTools @show-search-bar="handleOnShowSearchBar" />
        </div>
      </div>
    </div>

    <div class="under">
      <div class="inner">
        <div class="inner-item left">
          <NovaHeaderNavgations class="navigations" />
        </div>

        <div class="inner-item center"></div>

        <div class="inner-item right">
          <div
            :class="['mobile-search-bar', { 'search-panel-opened': isOpen }]"
          >
            <NovaHeaderSearchBar
              @on-focus="handleOnSearchFocus()"
              @focus-auto-complete-list="handleFocusAutoCompleteList"
              @select-auto-complete-item="selectAutoCompleteItem"
            />
          </div>
          <NovaChatButton v-if="showChatButton" :mode="ChatButtonMode.HEADER" />
        </div>
      </div>
    </div>
    <NovaSearchPanel :key-type="keyType" :active-index="activeIndex" />
  </header>
</template>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: $color-bg-3;
  z-index: $z-index-header;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  @include transition(all 0.2s);

  .upper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
    .inner-item {
      &.right {
        padding-right: 20px;
        @include mobile {
          padding-right: 0;
        }
      }
    }
  }

  .under {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;

    @include mobile {
      height: 50px;
    }
  }

  .inner {
    @include inner-base;

    .inner-item-group {
      flex-grow: 1;
      display: flex;
      gap: 40px;
    }

    .inner-item {
      display: flex;
      align-items: center;

      &.left {
        @include inner-left-base;
        width: auto;
        padding-left: 20px;
      }

      &.center {
        @include inner-center-base;
      }

      &.right {
        @include inner-right-base;
        width: auto;
        max-width: 390px;
      }
    }
  }

  .bi-group {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 105px;

    @include mobile {
      width: 76px;
    }
  }

  @include tablet {
    .inner {
      max-width: $base-tablet-main-max-width;

      .inner-item {
        &.left {
          padding-left: 4px;
        }
      }
    }
  }

  @include mobile {
    .inner {
      padding: 0 25px;
      gap: 0;

      .inner-item {
        &.left {
          width: auto;
          padding-left: 0;
        }

        &.right {
          width: auto;
        }
      }
    }

    &.mobile-gnb-hide {
      display: none;
    }
  }

  .desktop-search-bar {
    display: none;

    @include mobile {
      display: flex;
    }
  }

  .mobile-search-bar {
    min-width: 308px;

    @include mobile {
      display: none;
    }
  }
  .search-panel-opened {
    z-index: 40;
  }
}
</style>
