<script setup lang="ts">
import type { NovaHeaderSearchBarEmits } from './NovaHeaderSearchBar.types'
import { ROUTES } from '@configs'

const emits = defineEmits<NovaHeaderSearchBarEmits>()
const route = useRoute()
const { t } = useI18n()
const searchStore = useSearchStore()
const layoutStore = useLayoutStore()
const openSearchStore = useOpenSearchStore()
const { gtEvent } = useGoogleTag()
const { selectedPanelKeyword, keyword } = storeToRefs(openSearchStore)

// const previousKeyword = ref('')

const isSearchPage = computed(() => {
  const routeName = useGetRouteName()
  return routeName.includes(ROUTES.SEARCH.name)
})

// 검색 페이지 내부에서 변경되는 값 감지 하려고 full path 사용
watch(
  () => route.fullPath,
  async () => {
    selectedPanelKeyword.value = isSearchPage.value ? searchStore.keyword : ''
    if (isSearchPage.value) {
      keyword.value = searchStore.keyword
      await openSearchStore.fetchAutoComplete({ keyword: keyword.value })
    } else {
      await openSearchStore.clearAutoComplete()
    }
  },
  { immediate: true }
)

watch(
  () => selectedPanelKeyword.value,
  () => {
    if (!selectedPanelKeyword.value) {
      openSearchStore.fetchAutoComplete({ keyword: '' })
      layoutStore['layer:close'](LayerType.SEARCH_PANEL)
    }
  }
)
const handleOnSearch = async () => {
  if (
    !selectedPanelKeyword.value ||
    selectedPanelKeyword.value.match(/^ *$/) !== null
  ) {
    selectedPanelKeyword.value = ''
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '헤더 > 검색 > 검색어 미입력시 Toast 노출',
      eventLabel: t('statusMessages.searchKeyword.empty'),
      eventSlot: '헤더',
      eventI18nAddr: useKoreanTranslation('statusMessages.searchKeyword.empty'),
      eventComponent: 'Input',
    })
    useToast(t('statusMessages.searchKeyword.empty'))
    return
  }
  const trim = selectedPanelKeyword.value.trim()
  searchStore.updateKeyword(trim)
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: '헤더 > 검색',
    eventLabel: 'search',
    eventSlot: '헤더',
    eventI18nAddr: 'search',
    eventComponent: 'Input',
    searchContents: trim,
  })
  await openSearchStore.fetchAutoComplete({
    keyword: trim,
  })
  await layoutStore['layer:close'](LayerType.SEARCH_PANEL)
  await useNavigations({ url: ROUTES.SEARCH.path, query: { keyword: trim } })
}

const resetAutoCompleteData = () => {
  openSearchStore.fetchAutoComplete({ keyword: '' })
  layoutStore['layer:close'](LayerType.SEARCH_PANEL)
}

const updateAutoCompleteData = useDebounce(async () => {
  keyword.value = selectedPanelKeyword.value
  const incompleteHangleRegex = /[ㄱ-ㅎㅏ-ㅣ]/g

  const cleanedKeyword = selectedPanelKeyword.value.replace(
    incompleteHangleRegex,
    ''
  )
  await openSearchStore.fetchAutoComplete({ keyword: cleanedKeyword })
  // if (cleanedKeyword && cleanedKeyword !== previousKeyword.value) {
  //   previousKeyword.value = cleanedKeyword
  //   await openSearchStore.fetchAutoComplete({ keyword: cleanedKeyword })
  // }
}, 100)

const onKeyup = (event: KeyboardEvent) => {
  if (event && event.key) {
    const ignoredKeys = ['ArrowUp', 'ArrowDown']
    if (!ignoredKeys.includes(event.key)) {
      updateAutoCompleteData()
    }
  }
}

const focusAutoCompleteList = useDebounce((direction: string) => {
  emits('focusAutoCompleteList', direction)
}, 100)

const selectAutoCompleteItem = useDebounce(() => {
  emits('selectAutoCompleteItem')
}, 100)
</script>

<template>
  <NovaInput
    v-model="selectedPanelKeyword"
    :placeholder="t('searchPlaceholder')"
    :type="'search'"
    theme="search"
    @on-focus="() => emits('onFocus')"
    @on-blur="() => emits('onBlur')"
    @on-search="handleOnSearch"
    @on-clear="resetAutoCompleteData"
    @keyup="onKeyup"
    @keydown.up.prevent="focusAutoCompleteList('up')"
    @keydown.down.prevent="focusAutoCompleteList('down')"
    @keydown.enter.prevent="selectAutoCompleteItem"
  >
    <template #tools>
      <NovaButtonIcon
        :icon="{ type: 'outline', name: 'search' }"
        class="search-button"
        @click="handleOnSearch"
      />
    </template>
  </NovaInput>
</template>

<style scoped lang="scss"></style>
