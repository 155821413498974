<script setup lang="ts">
import type { CSSProperties } from 'vue'
import { ROUTES } from '@configs'
import type { NovaSearchPanelProps } from '@components/NovaSearchPanel/NovaSearchPanel.type'

defineProps<NovaSearchPanelProps>()
const layoutStore = useLayoutStore()
const openSearchStore = useOpenSearchStore()
const searchStore = useSearchStore()
const { t } = useI18n()
const { autoComplete, keyword, selectedPanelKeyword } =
  storeToRefs(openSearchStore)
const { responsive, headerRect, headerInnerRect, layers } =
  storeToRefs(layoutStore)
const { gtEvent } = useGoogleTag()
const novaSearchPanelRef = ref<HTMLDivElement>()
const isOpen = computed(() => layers.value.includes(LayerType.SEARCH_PANEL))
const styles = computed<CSSProperties>(() => {
  if (responsive.value === 'mobile') {
    return {
      position: 'fixed',
      top: useGetStyleSize('64px'),
      left: useGetStyleSize(0),
      right: useGetStyleSize(0),
      width: '100%',
      height: '100%',
    }
  }
  return {
    top: useGetStyleSize(isOpen.value ? 160 : 180),
    right: useGetStyleSize(
      (headerRect.value?.width || 0) - (headerInnerRect.value?.right || 0),
      'px'
    ),
    maxHeight: `calc(100vh - ${useGetStyleSize(
      (headerRect.value?.height || 0) + 100
    )})`,
  }
})

const handleOnCloseSearchPanel = () => {
  layoutStore['layer:close'](LayerType.SEARCH_PANEL)
}

const highlightMatch = (item: string) => {
  const test = keyword.value?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // 특수문자 이스케이프
  const regex = new RegExp(`(${test})`, 'gi')
  return item.replace(regex, '<span style="color: #213acc">$1</span>')
}

const handleOnSearch = async (searchKeyword: string) => {
  if (!searchKeyword || searchKeyword.match(/^ *$/) !== null) {
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '헤더 > 검색 > 검색어 미입력시 Toast 노출',
      eventLabel: t('statusMessages.searchKeyword.empty'),
      eventSlot: '헤더',
      eventI18nAddr: useKoreanTranslation('statusMessages.searchKeyword.empty'),
      eventComponent: 'Input',
    })
    useToast(t('statusMessages.searchKeyword.empty'))
    return
  }
  const trim = searchKeyword.trim()
  searchStore.updateKeyword(trim)
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: '헤더 > 검색',
    eventLabel: 'search',
    eventSlot: '헤더',
    eventI18nAddr: 'search',
    eventComponent: 'Input',
    searchContents: trim,
  })
  keyword.value = searchKeyword
  selectedPanelKeyword.value = searchKeyword
  await handleOnCloseSearchPanel()
  await openSearchStore.fetchAutoComplete({
    keyword: trim,
  })
  await useNavigations({ url: ROUTES.SEARCH.path, query: { keyword: trim } })
}
onMounted(() => {
  window.addEventListener('resize', handleOnCloseSearchPanel)
})
onUnmounted(() => {
  window.removeEventListener('resize', handleOnCloseSearchPanel)
})
</script>

<template>
  <div
    ref="novaSearchPanelRef"
    :class="['nova-search-panel', { on: isOpen }]"
    :style="styles"
  >
    <div class="dim" @click="handleOnCloseSearchPanel" />
    <div v-show="autoComplete.length > 0" class="inner">
      <div class="search-ul-wrap">
        <ul>
          <li
            v-for="(item, index) in autoComplete"
            :key="index"
            :class="{ active: index === activeIndex }"
            @click="handleOnSearch(item)"
          >
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'search' }"
              :size="16"
              class="search-button"
            />
            <div v-dompurify-html="highlightMatch(item)" class="text"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.active {
  background-color: $color-bg-1;
}
.nova-search-panel {
  position: absolute;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  width: 100%;
  max-width: 375px;
  @include mobile {
    max-width: none;
  }

  > .dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
  }

  > .inner {
    height: 100%;
    max-height: inherit;
    //padding: 20px;
    border-radius: 15px;
    background-color: $color-white;
    box-shadow: 0 2px 4px 0 #00000014;
    overflow-x: hidden;
    overflow-y: overlay;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    @include mobile {
      height: calc(100% - 64px);
      box-shadow: none;
      border-radius: 0;
    }

    .search-ul-wrap {
      > ul {
        display: flex;
        flex-direction: column;
        align-content: center;
        //gap: 20px;
        > li {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          padding: 10px 20px;
          gap: 20px;
          cursor: pointer;
          .search-button {
            margin-top: 3px;
          }
          .text {
            @include text-style($text-body-14-regular);
            @include ellipsis(1);
            letter-spacing: normal;
          }
        }
      }
    }
  }

  &.on {
    z-index: 30;
    pointer-events: auto;

    > .inner {
      opacity: 1;
    }
  }
}

.highlight {
  color: red;
}
</style>
