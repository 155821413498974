import * as OpenSearchType from './type'
import OpenSearchApi from './url'
import { api } from '@api'
export default {
  /**
   * 자동 완성
   * @param payload
   */
  fetchAutoComplete(payload: OpenSearchType.AutoCompletePayload) {
    return api.get<OpenSearchType.AutoComplete>(
      OpenSearchApi.FETCH_AUTO_COMPLETE,
      {
        params: payload,
      }
    )
  },
}
